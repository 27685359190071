// src/components/experiences/DesktopExperience.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import ModelOrchestration from '../ModelOrchestration';
import TokenManagement from '../TokenManagement';
import KnowledgeGraph from '../KnowledgeGraph';
import ScrollIndicator from '../ScrollIndicator';

const Section = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4),
  scrollSnapAlign: 'start',
  overflow: 'hidden'
}));

const VisualizationContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1
}));

const GlassOverlay = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  left: '10%',
  maxWidth: '600px',
  background: 'rgba(0, 0, 0, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  color: 'white',
  zIndex: 10,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.8)',
    transform: 'scale(1.02)',
    transition: 'all 0.3s ease-in-out',
  }
}));

const Feature = styled(motion.div)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateX(10px)',
  }
}));

const Container = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflowY: 'scroll',
  scrollSnapType: 'y mandatory',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  scrollBehavior: 'smooth'
}));

const DesktopExperience = () => {
  const features = {
    modelOrchestration: [
      "Dynamic integration of cutting-edge AI models",
      "Real-time model selection and optimization",
      "Seamless scaling across enterprise workloads"
    ],
    ultraLarge: [
      "2M token context windows for comprehensive analysis",
      "Processing entire enterprise workflows in real-time",
      "Deep understanding of complex business domains"
    ],
    evolution: [
      "Self-evolving knowledge systems",
      "Continuous learning and adaptation",
      "Deep domain expertise that grows exponentially"
    ]
  };

  return (
    <Container id="container">
      <Section>
        <VisualizationContainer>
          <ModelOrchestration />
        </VisualizationContainer>
        <GlassOverlay
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h1" sx={{ 
            background: 'linear-gradient(45deg, #3a7bd5, #00d2ff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 3,
            fontSize: '4rem',
            fontWeight: 700
          }}>
            Deep Vertical AI
          </Typography>
          <Typography variant="h4" sx={{ 
            mb: 3,
            background: 'linear-gradient(45deg, #fff, rgba(255,255,255,0.7))',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
            Redefining the Boundaries of Enterprise AI
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            We architect and deploy AI systems that transcend traditional limitations, 
            creating intelligent solutions that think, learn, and evolve at enterprise scale.
          </Typography>
          {features.modelOrchestration.map((feature, index) => (
            <Feature
              key={index}
              whileHover={{ x: 10 }}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2">
                {feature}
              </Typography>
            </Feature>
          ))}
        </GlassOverlay>
        <ScrollIndicator sectionIndex={0} totalSections={4} />
      </Section>

      <Section>
        <VisualizationContainer>
          <TokenManagement />
        </VisualizationContainer>
        <GlassOverlay
          sx={{ right: '10%', left: 'auto' }}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h3" sx={{ 
            color: '#00d2ff',
            mb: 3,
            fontWeight: 600
          }}>
            Beyond Human Scale
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Our systems process and understand information at scales that transcend human capabilities, 
            enabling unprecedented insights and decision-making power.
          </Typography>
          {features.ultraLarge.map((feature, index) => (
            <Feature
              key={index}
              whileHover={{ x: 10 }}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2">
                {feature}
              </Typography>
            </Feature>
          ))}
        </GlassOverlay>
        <ScrollIndicator sectionIndex={1} totalSections={4} />
      </Section>

      <Section>
        <VisualizationContainer>
          <KnowledgeGraph />
        </VisualizationContainer>
        <GlassOverlay
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h3" sx={{ 
            color: '#42dca3',
            mb: 3,
            fontWeight: 600
          }}>
            Living Knowledge Systems
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            We create AI systems that don't just operate - they understand, evolve, and 
            transform entire business domains through continuous learning and adaptation.
          </Typography>
          {features.evolution.map((feature, index) => (
            <Feature
              key={index}
              whileHover={{ x: 10 }}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2">
                {feature}
              </Typography>
            </Feature>
          ))}
        </GlassOverlay>
        <ScrollIndicator sectionIndex={2} totalSections={4} />
      </Section>

      <Section sx={{ 
        minHeight: '50vh',
        background: 'linear-gradient(180deg, transparent, rgba(0,0,0,0.8))'
      }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" sx={{ 
              background: 'linear-gradient(45deg, #3a7bd5, #00d2ff)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 3,
              fontWeight: 700
            }}>
              The Future of Enterprise AI
            </Typography>
            <Typography variant="h5" sx={{ 
              color: 'rgba(255,255,255,0.8)',
              maxWidth: '800px',
              margin: '0 auto'
            }}>
              We're not just building AI systems.<br/>
              We're defining what's possible.
            </Typography>
          </Box>
        </motion.div>
        <ScrollIndicator sectionIndex={3} totalSections={4} />
      </Section>
    </Container>
  );
};

export default DesktopExperience;