// src/components/ModelOrchestration.js
import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

const Canvas = styled('canvas')({
  width: '100%',
  height: '100%',
  display: 'block',
  background: 'transparent'
});

class KandinskyVisualization {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = null;
    this.time = 0;
    this.circles = [];
    this.connections = [];
    this.isActive = true;
    this.lastTime = 0;
    this.initialize();
  }

  initialize() {
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d', { alpha: true });
      this.resize();
      this.createComposition();
    }
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    if (!this.ctx || !this.canvas) return;
    
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);
    this.createComposition();
  }

  createComposition() {
    if (!this.canvas) return;

    // Create circles inspired by Kandinsky's "Several Circles"
    this.circles = [];
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    const maxRadius = Math.min(this.canvas.width, this.canvas.height) * 0.15;

    // Primary circles representing major AI components
    const primaryColors = ['#3a7bd5', '#00d2ff', '#42dca3'];
    primaryColors.forEach((color, i) => {
      const angle = (i * Math.PI * 2) / primaryColors.length;
      const distance = maxRadius * 1.5;
      this.circles.push({
        x: centerX + Math.cos(angle) * distance,
        y: centerY + Math.sin(angle) * distance,
        radius: maxRadius * (0.8 + Math.random() * 0.4),
        color,
        speed: 0.2 + Math.random() * 0.3,
        phase: Math.random() * Math.PI * 2,
        pulseSpeed: 0.5 + Math.random() * 0.5,
        type: 'primary'
      });
    });

    // Secondary circles representing AI model interactions
    const secondaryCount = 8;
    for (let i = 0; i < secondaryCount; i++) {
      const angle = (i * Math.PI * 2) / secondaryCount;
      const distance = maxRadius * (2.5 + Math.random());
      const color = primaryColors[i % primaryColors.length];
      this.circles.push({
        x: centerX + Math.cos(angle) * distance,
        y: centerY + Math.sin(angle) * distance,
        radius: maxRadius * (0.3 + Math.random() * 0.3),
        color,
        speed: 0.3 + Math.random() * 0.4,
        phase: Math.random() * Math.PI * 2,
        pulseSpeed: 0.6 + Math.random() * 0.6,
        type: 'secondary'
      });
    }

    // Create dynamic connections
    this.connections = [];
    this.circles.forEach((circle, i) => {
      this.circles.forEach((target, j) => {
        if (i !== j && Math.random() < (circle.type === 'primary' ? 0.7 : 0.3)) {
          this.connections.push({
            source: circle,
            target,
            strength: Math.random() * 0.5 + 0.5,
            phase: Math.random() * Math.PI * 2,
            speed: Math.random() * 0.02 + 0.01
          });
        }
      });
    });
  }

  drawCircle(circle, time) {
    if (!this.ctx) return;

    const { ctx } = this;
    const pulseScale = 1 + Math.sin(time * circle.pulseSpeed + circle.phase) * 0.1;
    
    // Create sophisticated glow effect
    const gradient = ctx.createRadialGradient(
      circle.x, circle.y, 0,
      circle.x, circle.y, circle.radius * pulseScale
    );
    gradient.addColorStop(0, circle.color + '40');
    gradient.addColorStop(0.6, circle.color + '20');
    gradient.addColorStop(1, 'transparent');

    ctx.beginPath();
    ctx.arc(circle.x, circle.y, circle.radius * pulseScale, 0, Math.PI * 2);
    ctx.fillStyle = gradient;
    ctx.fill();

    // Add subtle ring
    ctx.beginPath();
    ctx.arc(circle.x, circle.y, circle.radius * pulseScale, 0, Math.PI * 2);
    ctx.strokeStyle = circle.color + '60';
    ctx.lineWidth = 2;
    ctx.stroke();

    // Add inner detail for primary circles
    if (circle.type === 'primary') {
      ctx.beginPath();
      ctx.arc(circle.x, circle.y, circle.radius * pulseScale * 0.7, 0, Math.PI * 2);
      ctx.strokeStyle = circle.color + '40';
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  }

  drawConnection(connection, time) {
    if (!this.ctx) return;

    const { ctx } = this;
    const { source, target, strength, phase, speed } = connection;

    // Create dynamic path
    ctx.beginPath();
    const startX = source.x;
    const startY = source.y;
    const endX = target.x;
    const endY = target.y;

    // Calculate control points for curved path
    const dx = endX - startX;
    const dy = endY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;
    const offset = Math.sin(time * speed + phase) * distance * 0.2;
    const perpX = -dy / distance * offset;
    const perpY = dx / distance * offset;

    // Draw curved path
    ctx.moveTo(startX, startY);
    ctx.quadraticCurveTo(
      midX + perpX,
      midY + perpY,
      endX,
      endY
    );

    // Create gradient for path
    const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
    gradient.addColorStop(0, source.color + '40');
    gradient.addColorStop(1, target.color + '40');
    
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 1 * strength;
    ctx.stroke();

    // Add energy particles along the path
    if (Math.random() < 0.1) {
      const t = Math.random();
      const x = startX + (endX - startX) * t + perpX * Math.sin(t * Math.PI);
      const y = startY + (endY - startY) * t + perpY * Math.sin(t * Math.PI);
      
      ctx.beginPath();
      ctx.arc(x, y, 2, 0, Math.PI * 2);
      ctx.fillStyle = source.color + '80';
      ctx.fill();
    }
  }

  update(deltaTime) {
    this.circles.forEach(circle => {
      circle.phase += circle.speed * deltaTime;
    });
  }

  draw(timestamp) {
    if (!this.ctx || !this.canvas || !this.isActive) return;

    if (!this.lastTime) {
      this.lastTime = timestamp;
    }
    const deltaTime = (timestamp - this.lastTime) / 1000;
    this.lastTime = timestamp;

    const { ctx, canvas } = this;
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'rgba(30, 42, 58, 0.1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    this.time += deltaTime;

    // Draw connections first
    this.connections.forEach(connection => this.drawConnection(connection, this.time));
    
    // Draw circles on top
    this.circles.forEach(circle => this.drawCircle(circle, this.time));
    
    this.update(deltaTime);

    if (this.isActive) {
      requestAnimationFrame((ts) => this.draw(ts));
    }
  }

  cleanup() {
    this.isActive = false;
    this.ctx = null;
    this.circles = [];
    this.connections = [];
    window.removeEventListener('resize', () => this.resize());
  }
}

const ModelOrchestration = () => {
  const canvasRef = useRef(null);
  const visualizationRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && !visualizationRef.current) {
      visualizationRef.current = new KandinskyVisualization(canvasRef.current);
      visualizationRef.current.draw(performance.now());
    }

    return () => {
      if (visualizationRef.current) {
        visualizationRef.current.cleanup();
        visualizationRef.current = null;
      }
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default ModelOrchestration;