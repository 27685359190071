// src/components/experiences/MobileExperience.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import MobileBackground from '../mobile/MobileBackground';

const MobileContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: theme.palette.background.default,
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  position: 'relative'
}));

const Section = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  zIndex: 2
}));

const Content = styled(motion.div)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.75)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
}));

const Feature = styled(motion.div)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  borderRadius: theme.spacing(1),
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-out',
  '&:active': {
    background: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateX(10px)',
  }
}));

const MobileExperience = () => {
  const features = {
    modelOrchestration: [
      "Dynamic integration of cutting-edge AI models",
      "Real-time model selection and optimization",
      "Seamless scaling across enterprise workloads"
    ],
    ultraLarge: [
      "2M token context windows for comprehensive analysis",
      "Processing entire enterprise workflows in real-time",
      "Deep understanding of complex business domains"
    ],
    evolution: [
      "Self-evolving knowledge systems",
      "Continuous learning and adaptation",
      "Deep domain expertise that grows exponentially"
    ]
  };

  return (
    <MobileContainer>
      <MobileBackground />
      
      <Section>
        <Content
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h1" sx={{ 
            background: 'linear-gradient(45deg, #3a7bd5, #00d2ff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '2.5rem',
            fontWeight: 700,
            mb: 2,
            letterSpacing: '-0.02em'
          }}>
            Deep Vertical AI
          </Typography>
          <Typography variant="h4" sx={{ 
            background: 'linear-gradient(45deg, #fff, rgba(255,255,255,0.7))',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '1.5rem',
            mb: 2,
            letterSpacing: '0.01em'
          }}>
            Redefining the Boundaries of Enterprise AI
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 3, 
            fontSize: '0.95rem',
            color: 'rgba(255,255,255,0.9)',
            lineHeight: 1.6
          }}>
            We architect and deploy AI systems that transcend traditional limitations, 
            creating intelligent solutions that think, learn, and evolve at enterprise scale.
          </Typography>
          {features.modelOrchestration.map((feature, index) => (
            <Feature
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)' }}>
                {feature}
              </Typography>
            </Feature>
          ))}
        </Content>
      </Section>

      <Section>
        <Content
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h3" sx={{ 
            color: '#00d2ff',
            fontSize: '2rem',
            fontWeight: 600,
            mb: 2,
            letterSpacing: '-0.01em'
          }}>
            Beyond Human Scale
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 3, 
            fontSize: '0.95rem',
            color: 'rgba(255,255,255,0.9)',
            lineHeight: 1.6
          }}>
            Our systems process and understand information at scales that transcend human capabilities, 
            enabling unprecedented insights and decision-making power.
          </Typography>
          {features.ultraLarge.map((feature, index) => (
            <Feature
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)' }}>
                {feature}
              </Typography>
            </Feature>
          ))}
        </Content>
      </Section>

      <Section>
        <Content
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h3" sx={{ 
            color: '#42dca3',
            fontSize: '2rem',
            fontWeight: 600,
            mb: 2,
            letterSpacing: '-0.01em'
          }}>
            Living Knowledge Systems
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 3, 
            fontSize: '0.95rem',
            color: 'rgba(255,255,255,0.9)',
            lineHeight: 1.6
          }}>
            We create AI systems that don't just operate - they understand, evolve, and 
            transform entire business domains through continuous learning and adaptation.
          </Typography>
          {features.evolution.map((feature, index) => (
            <Feature
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.8)' }}>
                {feature}
              </Typography>
            </Feature>
          ))}
        </Content>
      </Section>

      <Section sx={{ pb: 8 }}>
        <Content
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          sx={{ textAlign: 'center' }}
        >
          <Typography variant="h2" sx={{ 
            background: 'linear-gradient(45deg, #3a7bd5, #00d2ff)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '2rem',
            fontWeight: 700,
            mb: 2,
            letterSpacing: '-0.02em'
          }}>
            The Future of Enterprise AI
          </Typography>
          <Typography variant="h5" sx={{ 
            color: 'rgba(255,255,255,0.9)',
            fontSize: '1.2rem',
            lineHeight: 1.6
          }}>
            We're not just building AI systems.<br/>
            We're defining what's possible.
          </Typography>
        </Content>
      </Section>
    </MobileContainer>
  );
};

export default MobileExperience;