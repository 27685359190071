// src/components/TokenManagement.js
import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

const Canvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

class TokenVisualization {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.streams = [];
    this.splatters = []; // Pollock-inspired elements
    this.energyNodes = []; // Processing nodes
    this.maxStreams = 5;
    this.processingSpeed = 0.3;
    this.initialize();
  }

  initialize() {
    this.resize();
    for (let i = 0; i < this.maxStreams; i++) {
      this.addStream();
    }
    // Initialize energy nodes
    for (let i = 0; i < 3; i++) {
      this.energyNodes.push({
        x: Math.random() * this.canvas.width,
        y: Math.random() * this.canvas.height,
        radius: Math.random() * 30 + 20,
        color: `hsla(${Math.random() * 60 + 180}, 70%, 50%, 0.3)`,
        phase: Math.random() * Math.PI * 2
      });
    }
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);

    // Add mobile scaling
    const isMobile = window.innerWidth <= 600;
    this.scale = isMobile ? 0.7 : 1;
    this.streamWidth = isMobile ? 1.5 : 2;
    
    // Update stream properties
    this.streams.forEach(stream => {
        stream.width = stream.width * this.scale;
    });

    // Update energy nodes
    this.energyNodes.forEach(node => {
        node.radius = node.radius * this.scale;
    });
  }

  addStream() {
    const stream = {
      points: [{
        x: Math.random() * this.canvas.width,
        y: this.canvas.height + 100
      }],
      maxPoints: 50,
      color: `hsla(${Math.random() * 60 + 180}, 70%, 50%, 0.2)`,
      speed: Math.random() * this.processingSpeed + 0.1,
      width: Math.random() * 2 + 1,
      tokenCount: 0,
      maxTokens: 128000 / this.maxStreams,
      energy: Math.random() * 0.5 + 0.5 // Energy level affects behavior
    };
    this.streams.push(stream);
  }

  createSplatter(x, y, color) {
    const count = Math.floor(Math.random() * 5) + 3;
    for (let i = 0; i < count; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * 50 + 20;
      this.splatters.push({
        x: x + Math.cos(angle) * distance,
        y: y + Math.sin(angle) * distance,
        radius: Math.random() * 3 + 1,
        color: color,
        life: 1,
        decay: Math.random() * 0.02 + 0.01
      });
    }
  }

  updateStream(stream) {
    const lastPoint = stream.points[stream.points.length - 1];
    
    // Create more dynamic movement
    const energyInfluence = Math.sin(this.time * stream.energy);
    const newPoint = {
      x: lastPoint.x + (Math.random() - 0.5) * 50 * (1 + energyInfluence),
      y: lastPoint.y - stream.speed * 2 * (1 + Math.abs(energyInfluence))
    };
    
    stream.points.push(newPoint);
    if (stream.points.length > stream.maxPoints) {
      stream.points.shift();
    }

    stream.tokenCount += stream.speed * 100;
    if (stream.tokenCount > stream.maxTokens) {
      // Create energy burst effect
      this.createSplatter(lastPoint.x, lastPoint.y, stream.color);
      
      stream.points = [];
      stream.tokenCount = 0;
      stream.points.push({
        x: Math.random() * this.canvas.width,
        y: this.canvas.height + 100
      });
    }

    // Interact with energy nodes
    this.energyNodes.forEach(node => {
      const dx = newPoint.x - node.x;
      const dy = newPoint.y - node.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < node.radius * 2) {
        this.createSplatter(newPoint.x, newPoint.y, stream.color);
      }
    });
  }

  drawStream(stream) {
    if (stream.points.length < 2) return;

    const { ctx } = this;
    ctx.beginPath();
    ctx.moveTo(stream.points[0].x, stream.points[0].y);

    // Enhanced curve generation
    for (let i = 1; i < stream.points.length - 2; i++) {
      const xc = (stream.points[i].x + stream.points[i + 1].x) / 2;
      const yc = (stream.points[i].y + stream.points[i + 1].y) / 2;
      
      // Add energy-based variation
      const energyOffset = Math.sin(this.time * stream.energy + i) * 5;
      ctx.quadraticCurveTo(
        stream.points[i].x + energyOffset,
        stream.points[i].y,
        xc,
        yc
      );
    }

    if (stream.points.length > 2) {
      const last = stream.points.length - 1;
      ctx.quadraticCurveTo(
        stream.points[last - 1].x,
        stream.points[last - 1].y,
        stream.points[last].x,
        stream.points[last].y
      );
    }

    // Create dynamic gradient along the path
    const gradient = ctx.createLinearGradient(
      stream.points[0].x, stream.points[0].y,
      stream.points[stream.points.length - 1].x,
      stream.points[stream.points.length - 1].y
    );
    gradient.addColorStop(0, stream.color.replace('0.2', '0.1'));
    gradient.addColorStop(0.5, stream.color.replace('0.2', '0.3'));
    gradient.addColorStop(1, stream.color.replace('0.2', '0.1'));

    ctx.strokeStyle = gradient;
    ctx.lineWidth = stream.width * (1 + Math.sin(this.time * stream.energy) * 0.2);
    ctx.stroke();
  }

  drawEnergyNodes() {
    const { ctx } = this;
    
    this.energyNodes.forEach(node => {
      const gradient = ctx.createRadialGradient(
        node.x, node.y, 0,
        node.x, node.y, node.radius * (1 + Math.sin(this.time + node.phase) * 0.2)
      );
      gradient.addColorStop(0, node.color.replace('0.3', '0.4'));
      gradient.addColorStop(1, 'transparent');

      ctx.beginPath();
      ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();
    });
  }

  drawSplatters() {
    const { ctx } = this;
    
    this.splatters = this.splatters.filter(splatter => {
      splatter.life -= splatter.decay;
      if (splatter.life <= 0) return false;

      ctx.beginPath();
      ctx.arc(splatter.x, splatter.y, splatter.radius, 0, Math.PI * 2);
      ctx.fillStyle = splatter.color.replace('0.2', splatter.life.toFixed(2));
      ctx.fill();

      return true;
    });
  }

  animate() {
    const { ctx, canvas } = this;
    
    // Clear with fade effect
    ctx.fillStyle = 'rgba(0, 0, 0, 0.02)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    this.time = Date.now() * 0.001;

    // Draw energy nodes
    this.drawEnergyNodes();

    // Update and draw streams
    this.streams.forEach(stream => {
      this.updateStream(stream);
      this.drawStream(stream);
    });

    // Draw Pollock-inspired splatters
    this.drawSplatters();

    requestAnimationFrame(() => this.animate());
  }
}

const TokenManagement = () => {
  const canvasRef = useRef(null);
  const visualizationRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && !visualizationRef.current) {
      visualizationRef.current = new TokenVisualization(canvasRef.current);
      visualizationRef.current.animate();
    }
  }, []);

  return (
    <Container>
      <Canvas ref={canvasRef} />
    </Container>
  );
};

export default TokenManagement;