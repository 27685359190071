// src/components/ScrollIndicator.js
import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { styled } from '@mui/material/styles';

const ScrollContainer = styled(motion.div)(({ theme, direction = 'down' }) => ({
  position: 'absolute',
  bottom: direction === 'down' ? theme.spacing(4) : 'auto',
  top: direction === 'up' ? theme.spacing(4) : 'auto',
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  zIndex: 10,
  pointerEvents: 'none',
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: 'none'
  }
}));

const ParticleCanvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none'
});

class ParticleSystem {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.particles = [];
    this.mousePosition = { x: 0, y: 0 };
    this.initialize();
  }

  initialize() {
    this.resize();
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);
  }

  createParticle(direction) {
    const particle = {
      x: Math.random() * this.canvas.width,
      y: direction === 'down' ? 0 : this.canvas.height,
      size: Math.random() * 2 + 1,
      speedY: (direction === 'down' ? 1 : -1) * (Math.random() * 1 + 0.5),
      opacity: Math.random() * 0.5 + 0.3,
      color: Math.random() > 0.5 ? '#3a7bd5' : '#00d2ff'
    };
    this.particles.push(particle);
  }

  animate(direction) {
    const { ctx, canvas } = this;
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Create new particles
    if (Math.random() < 0.2) {
      this.createParticle(direction);
    }

    // Update and draw particles
    this.particles = this.particles.filter(particle => {
      particle.y += particle.speedY;
      
      // Remove particles that are off screen
      if (direction === 'down' && particle.y > canvas.height) return false;
      if (direction === 'up' && particle.y < 0) return false;

      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = `${particle.color}${Math.floor(particle.opacity * 255).toString(16).padStart(2, '0')}`;
      ctx.fill();

      return true;
    });

    requestAnimationFrame(() => this.animate(direction));
  }
}

const Arrow = styled(motion.div)(({ theme, direction = 'down' }) => ({
  width: '20px',
  height: '20px',
  border: 'solid rgba(255, 255, 255, 0.7)',
  borderWidth: '0 2px 2px 0',
  transform: direction === 'down' ? 'rotate(45deg)' : 'rotate(-135deg)',
  marginTop: theme.spacing(1),
}));

const ScrollIndicator = ({ sectionIndex, totalSections }) => {
  const controls = useAnimation();
  const [isVisible, setIsVisible] = useState(true);
  const canvasRef = useRef(null);
  const particleSystemRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const currentSection = Math.floor(scrollPosition / windowHeight);
      
      if (currentSection === sectionIndex) {
        setIsVisible(true);
        controls.start({ opacity: 1 });
      } else {
        setIsVisible(false);
        controls.start({ opacity: 0 });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls, sectionIndex]);

  useEffect(() => {
    if (canvasRef.current && !particleSystemRef.current) {
      particleSystemRef.current = new ParticleSystem(canvasRef.current);
      particleSystemRef.current.animate(sectionIndex === totalSections - 1 ? 'up' : 'down');
    }
  }, [sectionIndex, totalSections]);

  const direction = sectionIndex === totalSections - 1 ? 'up' : 'down';

  return (
    <ScrollContainer
      direction={direction}
      initial={{ opacity: 1 }}
      animate={controls}
      transition={{ duration: 0.5 }}
    >
      <ParticleCanvas ref={canvasRef} />
      <Typography 
        variant="caption" 
        sx={{ 
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          fontSize: '0.7rem',
          mixBlendMode: 'difference'
        }}
      >
        {direction === 'down' ? 'Scroll to Explore' : 'Return to Top'}
      </Typography>
      <Arrow
        direction={direction}
        animate={{ 
          y: direction === 'down' ? [0, 10, 0] : [0, -10, 0]
        }}
        transition={{ 
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </ScrollContainer>
  );
};

export default ScrollIndicator;