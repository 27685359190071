// src/components/KnowledgeGraph.js
import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
}));

const Canvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

class MondrianKnowledgeVisualization {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.time = 0;
    this.structures = [];
    this.connections = [];
    this.flows = [];
    this.initialize();
  }

  initialize() {
    this.resize();
    this.createStructures();
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    const dpr = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);

    // Add mobile scaling
    const isMobile = window.innerWidth <= 600;
    this.scale = isMobile ? 0.7 : 1;

    // Update structure sizes
    this.structures.forEach(structure => {
        structure.width *= this.scale;
        structure.height *= this.scale;
    });

    // Recalculate grid
    this.createStructures();
  }

  createStructures() {
    // Primary knowledge domains represented as Mondrian-inspired rectangles
    const domains = [
      { name: 'Financial Analysis', color: '#3a7bd5' },
      { name: 'Market Intelligence', color: '#00d2ff' },
      { name: 'Strategic Planning', color: '#42dca3' }
    ];

    // Create grid-based structure
    const gridSize = 4;
    const cellWidth = this.canvas.width / gridSize;
    const cellHeight = this.canvas.height / gridSize;

    domains.forEach((domain, index) => {
      // Create multiple rectangles for each domain
      for (let i = 0; i < 3; i++) {
        const width = cellWidth * (Math.random() * 1.5 + 0.5);
        const height = cellHeight * (Math.random() * 1.5 + 0.5);
        const x = cellWidth * Math.floor(Math.random() * (gridSize - 1));
        const y = cellHeight * Math.floor(Math.random() * (gridSize - 1));

        this.structures.push({
          x,
          y,
          width,
          height,
          color: domain.color,
          name: domain.name,
          velocity: {
            x: (Math.random() - 0.5) * 0.2,
            y: (Math.random() - 0.5) * 0.2
          },
          rotation: Math.random() * Math.PI * 2,
          rotationSpeed: (Math.random() - 0.5) * 0.02
        });
      }
    });

    // Create dynamic connections between structures
    this.structures.forEach((structure, i) => {
      this.structures.forEach((target, j) => {
        if (i !== j && Math.random() < 0.3) {
          this.connections.push({
            source: structure,
            target,
            strength: Math.random() * 0.5 + 0.5,
            phase: Math.random() * Math.PI * 2
          });
        }
      });
    });
  }

  drawStructure(structure) {
    const { ctx } = this;
    ctx.save();
    ctx.translate(
      structure.x + structure.width / 2,
      structure.y + structure.height / 2
    );
    ctx.rotate(structure.rotation);

    // Draw glow effect
    const gradient = ctx.createRadialGradient(
      0, 0, 0,
      0, 0, Math.max(structure.width, structure.height)
    );
    gradient.addColorStop(0, structure.color + '20');
    gradient.addColorStop(1, 'transparent');
    ctx.fillStyle = gradient;
    ctx.fillRect(
      -structure.width / 2 - 20,
      -structure.height / 2 - 20,
      structure.width + 40,
      structure.height + 40
    );

    // Draw main rectangle
    ctx.fillStyle = structure.color + '15';
    ctx.strokeStyle = structure.color;
    ctx.lineWidth = 2;
    ctx.fillRect(
      -structure.width / 2,
      -structure.height / 2,
      structure.width,
      structure.height
    );
    ctx.strokeRect(
      -structure.width / 2,
      -structure.height / 2,
      structure.width,
      structure.height
    );

    // Draw inner lines (Mondrian-style)
    const lineCount = 3;
    for (let i = 1; i < lineCount; i++) {
      ctx.beginPath();
      ctx.moveTo(-structure.width / 2, (-structure.height / 2) + (structure.height * i / lineCount));
      ctx.lineTo(structure.width / 2, (-structure.height / 2) + (structure.height * i / lineCount));
      ctx.strokeStyle = structure.color + '30';
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo((-structure.width / 2) + (structure.width * i / lineCount), -structure.height / 2);
      ctx.lineTo((-structure.width / 2) + (structure.width * i / lineCount), structure.height / 2);
      ctx.stroke();
    }

    ctx.restore();
  }

  drawConnection(connection) {
    const { ctx } = this;
    const { source, target, strength, phase } = connection;

    // Calculate center points
    const sourceX = source.x + source.width / 2;
    const sourceY = source.y + source.height / 2;
    const targetX = target.x + target.width / 2;
    const targetY = target.y + target.height / 2;

    // Create orthogonal connection paths (Mondrian-style)
    ctx.beginPath();
    ctx.moveTo(sourceX, sourceY);
    
    // Determine intermediate points for orthogonal path
    const midX = (sourceX + targetX) / 2;
    const midY = (sourceY + targetY) / 2;
    
    ctx.lineTo(midX, sourceY);
    ctx.lineTo(midX, midY);
    ctx.lineTo(midX, targetY);
    ctx.lineTo(targetX, targetY);

    // Create gradient for connection
    const gradient = ctx.createLinearGradient(sourceX, sourceY, targetX, targetY);
    gradient.addColorStop(0, source.color + '30');
    gradient.addColorStop(1, target.color + '30');
    
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 1 * strength;
    ctx.stroke();

    // Draw knowledge flow particles
    const particleCount = 2;
    const totalLength = Math.abs(sourceX - targetX) + Math.abs(sourceY - targetY);
    
    for (let i = 0; i < particleCount; i++) {
      const t = ((this.time * 0.5 + i / particleCount) % 1);
      let x, y;
      
      // Calculate particle position along orthogonal path
      if (t < 0.25) {
        x = sourceX + (midX - sourceX) * (t * 4);
        y = sourceY;
      } else if (t < 0.5) {
        x = midX;
        y = sourceY + (midY - sourceY) * ((t - 0.25) * 4);
      } else if (t < 0.75) {
        x = midX;
        y = midY + (targetY - midY) * ((t - 0.5) * 4);
      } else {
        x = midX + (targetX - midX) * ((t - 0.75) * 4);
        y = targetY;
      }

      ctx.beginPath();
      ctx.arc(x, y, 2, 0, Math.PI * 2);
      ctx.fillStyle = source.color;
      ctx.fill();
    }
  }

  update() {
    // Update structure positions and rotations
    this.structures.forEach(structure => {
      structure.rotation += structure.rotationSpeed;
      
      // Update position
      structure.x += structure.velocity.x;
      structure.y += structure.velocity.y;

      // Boundary checking
      if (structure.x < 0 || structure.x + structure.width > this.canvas.width) {
        structure.velocity.x *= -1;
      }
      if (structure.y < 0 || structure.y + structure.height > this.canvas.height) {
        structure.velocity.y *= -1;
      }
    });

    // Create new knowledge flows
    if (Math.random() < 0.05) {
      const source = this.structures[Math.floor(Math.random() * this.structures.length)];
      const target = this.structures[Math.floor(Math.random() * this.structures.length)];
      
      if (source !== target) {
        this.flows.push({
          source,
          target,
          progress: 0,
          speed: Math.random() * 0.01 + 0.005
        });
      }
    }

    // Update existing flows
    this.flows = this.flows.filter(flow => {
      flow.progress += flow.speed;
      return flow.progress < 1;
    });
  }

  draw() {
    const { ctx, canvas } = this;
    
    // Clear with fade effect
    ctx.fillStyle = 'rgba(30, 42, 58, 0.1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    this.time += 0.01;

    // Draw connections
    this.connections.forEach(connection => this.drawConnection(connection));

    // Draw structures
    this.structures.forEach(structure => this.drawStructure(structure));

    // Update state
    this.update();

    requestAnimationFrame(() => this.draw());
  }
}

const KnowledgeGraph = () => {
  const canvasRef = useRef(null);
  const visualizationRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && !visualizationRef.current) {
      visualizationRef.current = new MondrianKnowledgeVisualization(canvasRef.current);
      visualizationRef.current.draw();
    }
  }, []);

  return (
    <Container>
      <Canvas ref={canvasRef} />
    </Container>
  );
};

export default KnowledgeGraph;