// src/App.js
import React from 'react';
import { ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import { theme } from './theme/theme';
import DesktopExperience from './components/experiences/DesktopExperience';
import MobileExperience from './components/experiences/MobileExperience';
import './styles/animations.css';

const App = () => {
  // Use theme directly in useMediaQuery
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  console.log('Rendering App, isMobile:', isMobile); // Add debug logging

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isMobile ? <MobileExperience /> : <DesktopExperience />}
    </ThemeProvider>
  );
};

export default App;