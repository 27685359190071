// src/components/mobile/MobileBackground.js
import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

const Canvas = styled('canvas')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  background: 'transparent'
});

class MobileKandinskyBackground {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = null;
    this.time = 0;
    this.circles = [];
    this.connections = [];
    this.isActive = true;
    this.lastTime = 0;
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.scrollPosition = 0;
    this.initialize();
  }

  initialize() {
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d', { alpha: true });
      this.resize();
      this.createComposition();
    }
    window.addEventListener('resize', () => this.resize());
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
    });
  }

  resize() {
    if (!this.ctx || !this.canvas) return;
    
    const dpr = this.isIOS ? 1 : (window.devicePixelRatio || 1);
    const rect = this.canvas.getBoundingClientRect();
    this.canvas.width = rect.width * dpr;
    this.canvas.height = rect.height * dpr;
    this.ctx.scale(dpr, dpr);
    this.createComposition();
  }

  createComposition() {
    if (!this.canvas) return;

    this.circles = [];
    const centerX = this.canvas.width / 2;
    const centerY = this.canvas.height / 2;
    const maxRadius = Math.min(this.canvas.width, this.canvas.height) * 0.12;

    // Reduced number of elements for mobile
    const primaryColors = ['#3a7bd5', '#00d2ff', '#42dca3'];
    primaryColors.forEach((color, i) => {
      const angle = (i * Math.PI * 2) / primaryColors.length;
      const distance = maxRadius * 1.2;
      this.circles.push({
        x: centerX + Math.cos(angle) * distance,
        y: centerY + Math.sin(angle) * distance,
        radius: maxRadius * (0.7 + Math.random() * 0.3),
        color,
        speed: 0.15 + Math.random() * 0.2,
        phase: Math.random() * Math.PI * 2,
        pulseSpeed: 0.4 + Math.random() * 0.3,
        type: 'primary'
      });
    });

    // Fewer secondary circles for mobile
    const secondaryCount = this.isIOS ? 4 : 6;
    for (let i = 0; i < secondaryCount; i++) {
      const angle = (i * Math.PI * 2) / secondaryCount;
      const distance = maxRadius * (2 + Math.random());
      const color = primaryColors[i % primaryColors.length];
      this.circles.push({
        x: centerX + Math.cos(angle) * distance,
        y: centerY + Math.sin(angle) * distance,
        radius: maxRadius * (0.25 + Math.random() * 0.25),
        color,
        speed: 0.2 + Math.random() * 0.3,
        phase: Math.random() * Math.PI * 2,
        pulseSpeed: 0.5 + Math.random() * 0.4,
        type: 'secondary'
      });
    }

    // Fewer connections for mobile
    this.connections = [];
    this.circles.forEach((circle, i) => {
      this.circles.forEach((target, j) => {
        if (i !== j && Math.random() < (circle.type === 'primary' ? 0.5 : 0.2)) {
          this.connections.push({
            source: circle,
            target,
            strength: Math.random() * 0.4 + 0.3,
            phase: Math.random() * Math.PI * 2,
            speed: Math.random() * 0.015 + 0.008
          });
        }
      });
    });
  }

  drawCircle(circle, time) {
    if (!this.ctx) return;

    const { ctx } = this;
    const scrollOffset = this.scrollPosition * 0.1;
    const pulseScale = 1 + Math.sin(time * circle.pulseSpeed + circle.phase) * 0.08;
    
    const gradient = ctx.createRadialGradient(
      circle.x, circle.y + scrollOffset, 0,
      circle.x, circle.y + scrollOffset, circle.radius * pulseScale
    );
    gradient.addColorStop(0, circle.color + '30');
    gradient.addColorStop(0.6, circle.color + '15');
    gradient.addColorStop(1, 'transparent');

    ctx.beginPath();
    ctx.arc(circle.x, circle.y + scrollOffset, circle.radius * pulseScale, 0, Math.PI * 2);
    ctx.fillStyle = gradient;
    ctx.fill();

    if (circle.type === 'primary' && !this.isIOS) {
      ctx.beginPath();
      ctx.arc(circle.x, circle.y + scrollOffset, circle.radius * pulseScale * 0.7, 0, Math.PI * 2);
      ctx.strokeStyle = circle.color + '30';
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  }

  drawConnection(connection, time) {
    if (!this.ctx) return;

    const { ctx } = this;
    const { source, target, strength, phase, speed } = connection;
    const scrollOffset = this.scrollPosition * 0.1;

    ctx.beginPath();
    const startX = source.x;
    const startY = source.y + scrollOffset;
    const endX = target.x;
    const endY = target.y + scrollOffset;

    const dx = endX - startX;
    const dy = endY - startY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;
    const offset = Math.sin(time * speed + phase) * distance * 0.15;
    const perpX = -dy / distance * offset;
    const perpY = dx / distance * offset;

    ctx.moveTo(startX, startY);
    ctx.quadraticCurveTo(
      midX + perpX,
      midY + perpY,
      endX,
      endY
    );

    const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
    gradient.addColorStop(0, source.color + '30');
    gradient.addColorStop(1, target.color + '30');
    
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 1 * strength;
    ctx.stroke();

    if (!this.isIOS && Math.random() < 0.05) {
      const t = Math.random();
      const x = startX + (endX - startX) * t + perpX * Math.sin(t * Math.PI);
      const y = startY + (endY - startY) * t + perpY * Math.sin(t * Math.PI);
      
      ctx.beginPath();
      ctx.arc(x, y, 1.5, 0, Math.PI * 2);
      ctx.fillStyle = source.color + '60';
      ctx.fill();
    }
  }

  update(deltaTime) {
    this.circles.forEach(circle => {
      circle.phase += circle.speed * deltaTime;
    });
  }

  draw(timestamp) {
    if (!this.ctx || !this.canvas || !this.isActive) return;

    if (!this.lastTime) {
      this.lastTime = timestamp;
    }
    const deltaTime = (timestamp - this.lastTime) / 1000;
    this.lastTime = timestamp;

    const { ctx, canvas } = this;
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'rgba(30, 42, 58, 0.1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    this.time += deltaTime;

    // Limit updates on iOS
    if (!this.isIOS || this.time % 2 === 0) {
      this.connections.forEach(connection => this.drawConnection(connection, this.time));
      this.circles.forEach(circle => this.drawCircle(circle, this.time));
      this.update(deltaTime);
    }

    if (this.isActive) {
      requestAnimationFrame((ts) => this.draw(ts));
    }
  }

  cleanup() {
    this.isActive = false;
    this.ctx = null;
    this.circles = [];
    this.connections = [];
    window.removeEventListener('resize', () => this.resize());
    window.removeEventListener('scroll', () => {});
  }
}

const MobileBackground = () => {
  const canvasRef = useRef(null);
  const visualizationRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && !visualizationRef.current) {
      visualizationRef.current = new MobileKandinskyBackground(canvasRef.current);
      visualizationRef.current.draw(performance.now());
    }

    return () => {
      if (visualizationRef.current) {
        visualizationRef.current.cleanup();
        visualizationRef.current = null;
      }
    };
  }, []);

  return <Canvas ref={canvasRef} />;
};

export default MobileBackground;